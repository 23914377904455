import React from 'react';
import { useTranslation } from 'react-i18next';
import { ReactAlarmSubscriptionQueryDocument, ReactChangeUserSubscriptionsDocument } from '$typings/graphql-codegen';
import CardContent from '$components/cards/card-content/card-content.react';
import CardHeader from '$components/cards/card-header/card-header.react';
import Card from '$components/cards/card/card.react';
import Skeleton from '$components/placeholders/skeleton/skeleton';
import ErrorText from '$components/texts/error-text/error-text.react';
import Button from '$components/buttons/button.react';
import Switch from '$components/switch/switch.react';
import { mutate, useQuery } from '$lib/hooks/fetch-utillities';

const AlarmSubscriptionCard = () => {
  const [t] = useTranslation();
  const { data, loading, revalidate } = useQuery(ReactAlarmSubscriptionQueryDocument);

  function changeSubscriptionsMutation(alarmType: number, activate: Boolean) {
    mutate(ReactChangeUserSubscriptionsDocument, { alarmType, activate });
  }

  if (!data) {
    return (
      <Card>
        <CardHeader>{t('ui_userprofile_headers_alarmsubscriptions')}</CardHeader>
        <CardContent className="relative">
          <Skeleton rowCount={7} gap={5} columnCount={2} rowHeight={34} />
          {!loading && (
            <div className="center-content absolute-fill flex column bg-white-80">
              <ErrorText bold>We could not fetch data</ErrorText>
              <Button variant="tertiary" onClick={revalidate}>
                Try again
              </Button>
            </div>
          )}
        </CardContent>
      </Card>
    );
  }

  return (
    <Card>
      <CardHeader>{t('ui_userprofile_headers_alarmsubscriptions')}</CardHeader>
      {data.currentUser.alarmSubscriptions && (
      <CardContent>
        <Switch
          text={t('ui_userprofile_labels_lowlevel')}
          checked={data.currentUser.alarmSubscriptions.lowLevel}
          onChange={e => changeSubscriptionsMutation(2, !!e.target.value)}
        />
        <Switch
          className="mar-ts"
          text={t('ui_userprofile_labels_prelowlevel')}
          checked={data.currentUser.alarmSubscriptions.preLowLevel}
          onChange={e => changeSubscriptionsMutation(3, !!e.target.value)}
        />
        <Switch
          className="mar-ts"
          text={t('ui_userprofile_labels_30percentcapacity')}
          checked={data.currentUser.alarmSubscriptions.percentCapacity30}
          onChange={e => changeSubscriptionsMutation(6, !!e.target.value)}
        />
        <Switch
          className="mar-ts"
          text={t('ui_common_labels_tankcalibration')}
          checked={data.currentUser.alarmSubscriptions.tankCalibration}
          onChange={e => changeSubscriptionsMutation(4, !!e.target.value)}
        />
        <Switch
          className="mar-ts"
          text={t('ui_userprofile_labels_offline')}
          checked={data.currentUser.alarmSubscriptions.offline}
          onChange={e => changeSubscriptionsMutation(5, !!e.target.value)}
        />
        <Switch
          className="mar-ts"
          text={t('ui_userprofile_labels_dosecalibration')}
          checked={data.currentUser.alarmSubscriptions.doseCalibration}
          onChange={e => changeSubscriptionsMutation(8, !!e.target.value)}
        />
        <Switch
          className="mar-ts"
          text={t('ui_userprofile_labels_seriousdosecalibration')}
          checked={data.currentUser.alarmSubscriptions.seriousDoseCalibration}
          onChange={e => changeSubscriptionsMutation(9, !!e.target.value)}
        />
        <Switch
          className="mar-ts"
          text={t('ui_userprofile_labels_brokensensor')}
          checked={data.currentUser.alarmSubscriptions.brokenSensor}
          onChange={e => changeSubscriptionsMutation(10, !!e.target.value)}
        />
        <Switch
          className="mar-ts"
          text={t('ui_userprofile_labels_documentnotifications')}
          checked={data.currentUser.alarmSubscriptions.documentNotifications}
          onChange={e => changeSubscriptionsMutation(11, !!e.target.value)}
        />
        <Switch
          className="mar-ts"
          text={t('ui_userprofile_labels_deliverynotes')}
          checked={data.currentUser.alarmSubscriptions.deliveryNotes}
          onChange={e => changeSubscriptionsMutation(12, !!e.target.value)}
        />        
      </CardContent>
      )}
    </Card>
  );
};

export default AlarmSubscriptionCard;
