import React, { FC, useState } from 'react';
import { ReactUserInfoQueryDocument } from '../../../../../../custom_typings/graphql-codegen';
import CardHeader from '$components/cards/card-header/card-header.react';
import Card from '$components/cards/card/card.react';
import { useTranslation } from 'react-i18next';
import EditContactInfoForm from './modules/edit-contact-info-form/edit-contact-info-form';
import ClickableIcon from '$components/icons/icon-clickable/clickable-icon.react';

import ContactRows from './modules/contact-rows/contact-rows';
import CardContent from '$components/cards/card-content/card-content.react';
import Skeleton from '$components/placeholders/skeleton/skeleton';
import ErrorText from '$components/texts/error-text/error-text.react';
import Button from '$components/buttons/button.react';
import { useQuery } from '$lib/hooks/fetch-utillities';

interface IContactInfoCardProps {
  className?: string;
}

const ContactInfoCard: FC<IContactInfoCardProps> = ({ className }) => {
  const { data, loading, revalidate } = useQuery(ReactUserInfoQueryDocument);
  const [addingContactInfo, setAddContactInfo] = useState(false);

  const [t] = useTranslation();

  if (!data) {
    return (
      <Card className={className}>
        <CardHeader>{t('ui_usercontacts_information_header')}</CardHeader>
        <CardContent className="relative">
          <Skeleton rowCount={3} />
          {!loading && (
            <div className="center-content absolute-fill flex column bg-white-80">
              <ErrorText bold>We could not fetch data</ErrorText>
              <Button variant="tertiary" onClick={revalidate}>
                Try again
              </Button>
            </div>
          )}
        </CardContent>
      </Card>
    );
  }

  return (
    <>
      <Card className={className}>
        <CardHeader
          action={
            !addingContactInfo ? (
              <ClickableIcon
                name="fa-plus"
                onClick={() => setAddContactInfo(true)}
              />
            ) : (
              undefined
            )
          }
        >
          {t('Public contact information')}
        </CardHeader>
        {!addingContactInfo && data?.currentUser.user && (
          <ContactRows user={data?.currentUser.user} />
        )}

        {addingContactInfo && data.currentUser.user && (
          <EditContactInfoForm
            closeForm={() => setAddContactInfo(false)}
            mutationVariables={{
              user: data.currentUser.user
            }}
            defaultValues={{
              name: data.currentUser.user.name,
              email: data.currentUser.user.email,
              telephone: data.currentUser.user.telephoneNumber,
              syncedWithUserInformation: true
            }}
          />
        )}
      </Card>
    </>
  );
};

export default ContactInfoCard;
