import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactUserInfoQueryDocument } from '../../../../../../custom_typings/graphql-codegen';
import CardHeader from '$components/cards/card-header/card-header.react';
import Card from '$components/cards/card/card.react';
import ClickableIcon from '$components/icons/icon-clickable/clickable-icon.react';
import UserInfoForm from './modules/user-info-form';
import UserInfoView from './modules/user-info-view';
import Skeleton from '$components/placeholders/skeleton/skeleton';
import CardContent from '$components/cards/card-content/card-content.react';
import Button from '$components/buttons/button.react';
import ErrorText from '$components/texts/error-text/error-text.react';
import { useQuery } from '$lib/hooks/fetch-utillities';

const UserInfoCard = () => {
  const [t] = useTranslation();
  const { data, loading, revalidate } = useQuery(ReactUserInfoQueryDocument);
  const [editingUser, setEditingUser] = useState(false);

  if (!data) {
    return (
      <Card>
        <CardHeader>{t('ui_userprofile_headers_title')}</CardHeader>
        <CardContent className="relative">
          <Skeleton rowCount={3} />
          {!loading && (
            <div className="center-content absolute-fill flex column bg-white-80">
              <ErrorText bold>We could not fetch data</ErrorText>
              <Button variant="tertiary" onClick={revalidate}>
                Try again
              </Button>
            </div>
          )}
        </CardContent>
      </Card>
    );
  }

  return (
    <Card>
      <CardHeader
        action={
          editingUser ? (
            <ClickableIcon
              onClick={() => setEditingUser(false)}
              name={'fa-times'}
            />
          ) : (
            <ClickableIcon
              onClick={() => setEditingUser(true)}
              name={'fa-pencil'}
            />
          )
        }
      >
        {t('ui_userprofile_headers_title')}
      </CardHeader>
      {editingUser && data.currentUser.user && (
        <UserInfoForm
          closeForm={() => setEditingUser(false)}
          mutationVariables={{ user: data.currentUser.user }}
          defaultValues={{
            name: data.currentUser.user.name,
            email: data.currentUser.user.email,
            telephoneNumber: data.currentUser.user.telephoneNumber
          }}
        />
      )}
      {!editingUser && data.currentUser.user && <UserInfoView user={data.currentUser.user} />}
    </Card>
  );
};

export default UserInfoCard;
