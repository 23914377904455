import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../../../../components/buttons/button.react';
import CardContent from '$components/cards/card-content/card-content.react';
import CardHeader from '$components/cards/card-header/card-header.react';
import Card from '$components/cards/card/card.react';
import ChangePasswordForm from './modules/change-passord-form';

const ChangePasswordCard = () => {
  const { t } = useTranslation();
  const [editPassword, setEditPassword] = useState(false);

  return (
    <Card className="userprofile-card change-password hide-errors">
      <CardHeader>{t('ui_userprofile_headers_changepassword')}</CardHeader>
      <CardContent>        
        {t('ui_userprofile_labels_changepassword_notice')}
      </CardContent>
      {!editPassword ? (
        <CardContent>          
          <Button onClick={() => setEditPassword(true)}>
            {t('ui_userprofile_changepassword')}
          </Button>
        </CardContent>
      ) : (
        <>
          <CardContent>
            {t('ui_userprofile_labels_changepassword_passwordrules')}
          </CardContent>
          <ChangePasswordForm closeForm={() => { 
            setEditPassword(false);
          }} />
        </>
      )}
    </Card>
  );
};

export default ChangePasswordCard;
