import Button from '$components/buttons/button.react';
import CardContent from '$components/cards/card-content/card-content.react';
import RequireConfirmation from '$components/require-confirmation/require-confirmation.react';
import { isSomething } from '$lib/helpers';
import { mutate, useQuery } from '$lib/hooks/fetch-utillities';
import {
  Contact,
  CurrentUserInfo,
  DeleteUserContactDocument,
  UserContactsDocument
} from '$typings/graphql-codegen';
import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  getSession,
  getUserFeatures
} from '../../../../../../../config/sessionService';
import ContactRow from '../contact-row/contact-row';
import EditContactInfoForm, {
  IEditContactInformationFormTypes
} from '../edit-contact-info-form/edit-contact-info-form';

interface IContactRowsProps {
  className?: string;
  user: CurrentUserInfo;
}

const ContactRows: FC<IContactRowsProps> = ({ user }) => {
  const { t } = useTranslation();
  const { data } = useQuery(UserContactsDocument, {
    userId: user.userId.toString()
  });
  const [requireConfirmation, setRequireConfirmation] = useState(false);
  const [editingContactInfo, setEditingContactInfo] = useState(false);
  const [pendingContact, setPendingContact] = useState<Contact | undefined>();
  const [formDefaultValues, setFormDefaultValuesState] = useState<
    Partial<IEditContactInformationFormTypes> | undefined
  >();

  function onSuccess() {
    setRequireConfirmation(false);
    setPendingContact(undefined);
  }

  function deleteContact(contactId: String) {
    mutate(
      DeleteUserContactDocument,
      { contactId: contactId },
      true,
      onSuccess
    );
  }

  function editContact(contact: Contact) {
    setFormDefaultValuesState({
      name: contact.name,
      email: contact.email || undefined,
      telephone: contact.telephoneNumber,
      description: contact.description || undefined,
      syncedWithUserInformation: isSomething(contact.syncedWithUserInformation)
        ? contact.syncedWithUserInformation
        : true
    });
    setPendingContact(contact);
    setEditingContactInfo(true);
  }

  function removeContact(contact: Contact) {
    setPendingContact(contact);
    if (contact.isContactFor.length) {
      return setRequireConfirmation(true);
    }
    deleteContact(contact.contactId);
  }

  const features = getUserFeatures();

  function isCurrentUser() {
    const session = getSession();
    return user && user.userId === session.currentUser.userId;
  }
  
  return (
    <div>
      {!editingContactInfo && data?.contacts.edges.map((contact: Contact) => (
        <ContactRow
          key={contact.userId}
          contact={contact}
          canEdit={features.manageUsers.write || isCurrentUser()}
          canRemove={features.manageUsers.write || isCurrentUser()}
          changeContact={() => editContact(contact)}
          removeContact={() => removeContact(contact)}
          showDescription={true}
        />
      ))}
      {data && !data.contacts.edges.length && (
        <CardContent>
          <p className="description">
            {t('ui_usercontacts_noinformation_part1')}
          </p>
          <p className="description">
            {t('ui_usercontacts_noinformation_part2')}
          </p>
          <Button onClick={() => setEditingContactInfo(true)}>
            {t('ui_usercontacts_addcontact')}
          </Button>
        </CardContent>
      )}
      {editingContactInfo && data && (
        <EditContactInfoForm
          closeForm={() => setEditingContactInfo(false)}
          mutationVariables={{
            user: user,
            contactId: pendingContact?.contactId
          }}
          defaultValues={formDefaultValues}
        />
      )}
      {requireConfirmation && (
        <RequireConfirmation
          onConfirm={() =>
            pendingContact && deleteContact(pendingContact.contactId)
          }
          onCancel={() => setRequireConfirmation(false)}
        >
          <p>
            {t('ui_usercontacts_removeconfirmation_part1', {
              count: pendingContact?.isContactFor.length
            })}
          </p>
          <p>{t('ui_usercontacts_removeconfirmation_part2')}</p>
        </RequireConfirmation>
      )}
    </div>
  );
};

export default ContactRows;
