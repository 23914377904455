import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { CurrentUserInfo } from '$typings/graphql-codegen';
import CardContent from '$components/cards/card-content/card-content.react';
import SimpleTable from '$components/tables/simple-table/simple-table/simple-table.react';
import SimpleTableRow from '$components/tables/simple-table/simple-table-row/simple-table-row.react';
import SimpleTableCell from '$components/tables/simple-table/simple-table-cell/simple-table-cell.react';

interface IUserInfoViewProp {
  user: CurrentUserInfo;
}

const UserInfoView: FC<IUserInfoViewProp> = ({ user }) => {
  const [t] = useTranslation();
  return (
    <CardContent>
      <SimpleTable>
        <SimpleTableRow>
          <SimpleTableCell>{t('ui_userprofile_labels_username')}</SimpleTableCell>
          <SimpleTableCell>{user.username}</SimpleTableCell>
        </SimpleTableRow>
        <SimpleTableRow>
          <SimpleTableCell>{t('ui_userprofile_labels_name')}</SimpleTableCell>
          <SimpleTableCell>{user.name}</SimpleTableCell>
        </SimpleTableRow>
        <SimpleTableRow>
          <SimpleTableCell>{t('ui_userprofile_labels_email')}</SimpleTableCell>
          <SimpleTableCell><a href={"mailto:"+ user.email}>{user.email}</a></SimpleTableCell>
        </SimpleTableRow>
        <SimpleTableRow>
          <SimpleTableCell>{t('ui_userprofile_labels_telephone')}</SimpleTableCell>
          <SimpleTableCell>{user.telephoneNumber}</SimpleTableCell>
        </SimpleTableRow>
      </SimpleTable>
    </CardContent>
  );
};

export default UserInfoView;
