import { validatePassword } from '$lib/validationHelpers';
import Button from '$components/buttons/button.react';
import CardContent from '$components/cards/card-content/card-content.react';
import CardFooter from '$components/cards/card-footer/card-footer.react';
import TextField from '$components/textbox/text-field.react';
import { ChangePasswordDocument } from '$typings/graphql-codegen';
import React, { FC } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import FormElement from '$components/forms/form-element/form-element.react';
import ErrorText from '$components/texts/error-text/error-text.react';
import { IGeneralFormError } from '$lib/hooks/reactHookFormHelper';
import { mutate } from '$lib/hooks/fetch-utillities';
import { isClientError } from '$pages/common/GraphQLFetcher';
import { ClientError } from 'graphql-request/dist/types';

interface IChangePasswordFormTypes extends IGeneralFormError {
  currentPassword: string;  
  password: string;
  confirmPassword: string;
}

interface IChangePasswordFormProps {
  closeForm: () => void;
}

const ChangePasswordForm: FC<IChangePasswordFormProps> = ({ closeForm }) => {
  const [t] = useTranslation();
  const {
    register,
    handleSubmit,
    getValues,
    setError,
    clearErrors,
    formState: { errors, isSubmitting }
  } = useForm<IChangePasswordFormTypes>();

  const validatePasswordField = (password: string) =>
    validatePassword(password) ||
    t<string>('ui_userprofile_labels_changepassword_invalidpassword');

  const validateConfirmPasswordField = (confirmedPassword: string) =>
    confirmedPassword === getValues().password ||
    t<string>('ui_userprofile_labels_changepassword_passworddoesnotmatch');

    const onError = (e : ClientError) => {
      if (!e || !isClientError(401, e.response.errors)) {
        setError('formError', {
          message: t('ui_common_error_save_item_failed')
        });
      } else {      
        setError('formError', {
          message: t('ui_userprofile_labels_changepassword_current_password_wrong')
        });
      }
    };

  const onSubmit: SubmitHandler<IChangePasswordFormTypes> = async formdata => {
    await mutate(
      ChangePasswordDocument,
      { newPassword: formdata.password, oldPassword: formdata.currentPassword },
      true,
      closeForm,
      onError
    );  
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <CardContent>        
        <FormElement label={t('ui_userprofile_labels_changepassword_current_password')}>
          <TextField
            type="password"
            required={true}
            error={errors.currentPassword?.message}
            {...register('currentPassword')}
          />
        </FormElement>
        <FormElement label={t('ui_userprofile_labels_changepassword_password')}>
          <TextField
            type="password"
            required={true}
            error={errors.password?.message}
            {...register('password', {
              validate: validatePasswordField
            })}
          />
        </FormElement>
        <FormElement
          label={t('ui_userprofile_labels_changepassword_confirmpassword')}
        >
          <TextField
            type="password"
            required={true}
            error={errors.confirmPassword?.message}
            {...register('confirmPassword', {
              validate: validateConfirmPasswordField
            })}
          />
        </FormElement>
        {errors.formError && (
          <ErrorText className="text-align-center mar_tm">
            {errors.formError?.message}
          </ErrorText>
        )}
      </CardContent>
      <CardFooter className="flex jsb">
        <Button onClick={closeForm} variant="secondary">
          {t('ui_common_cancel')}
        </Button>
        <Button
          onClick={() => clearErrors('formError')}
          processing={isSubmitting}
          type="submit" 
        >
          {errors.formError ? t('ui_common_try_again') : t('ui_common_save')}
        </Button>
      </CardFooter>
    </form>
  );
};

export default ChangePasswordForm;
