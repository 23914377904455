import React from 'react';
import { useTranslation } from 'react-i18next';
import CardContent from '$components/cards/card-content/card-content.react';
import CardHeader from '$components/cards/card-header/card-header.react';
import Card from '$components/cards/card/card.react';
import SimpleTable from '$components/tables/simple-table/simple-table/simple-table.react';
import SimpleTableCell from '$components/tables/simple-table/simple-table-cell/simple-table-cell.react';
import SimpleTableRow from '$components/tables/simple-table/simple-table-row/simple-table-row.react';

const YaraTelemetryCard = () => {
  const [t] = useTranslation();
  const buildVersion = document.head.querySelector('meta[name="build-version"]')?.getAttribute('content') || '0.0.0'; 
  return (
    <Card>
      <CardHeader>{t('ui_userprofile_headers_yaratelemetry')}</CardHeader>
      <CardContent className="relative">
        <SimpleTable>
          <SimpleTableRow>
            <SimpleTableCell>{t('ui_userprofile_headers_yaratelemetry_version')}</SimpleTableCell>
            <SimpleTableCell>{buildVersion}</SimpleTableCell>
          </SimpleTableRow>
          <SimpleTableRow>
            <SimpleTableCell>{t('ui_userprofile_headers_yaratelemetry_reset')}</SimpleTableCell>
            <SimpleTableCell><a href="/reset" target="_blank" title="Reset YT3 settings">{t('ui_userprofile_headers_yaratelemetry_resetlinktext')}</a></SimpleTableCell>
          </SimpleTableRow>
        </SimpleTable>        
      </CardContent>
    </Card>
  );
};

export default YaraTelemetryCard;
