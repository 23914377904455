import { Language, LanguagesDocument, UpdateCurrentUserSettingsDocument } from '$typings/graphql-codegen';
import React, { FC } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import Button from '$components/buttons/button.react';
import CardContent from '$components/cards/card-content/card-content.react';
import CardFooter from '$components/cards/card-footer/card-footer.react';
import Icon from '$components/icons/icon/icon.react';
import FormElement from '$components/forms/form-element/form-element.react';
import ErrorText from '$components/texts/error-text/error-text.react';
import { IGeneralFormError } from '$lib/hooks/reactHookFormHelper';
import { mutate, useQuery } from '$lib/hooks/fetch-utillities';
import Dropdown from '$components/dropdowns/dropdown/dropdown.react';
import { SiteDetailsTabEnum } from '$typings/graphql';
import { useCaseInsensitiveTranslation } from '$lib/hooks/case-insensitive-translation';

interface IUserSettingsFormValues extends IGeneralFormError {
  preferredLanguage: string;
  siteDetailsDefaultTab: SiteDetailsTabEnum;
}

interface IUserSettingsFormProps {
  defaultValues: Partial<IUserSettingsFormValues>;
  closeForm: () => void;
  mutationVariables: { 
    hideDashboardWelcome: boolean, 
    siteDetailsDefaultTab: SiteDetailsTabEnum,
  };
}

const UserSettingsForm: FC<IUserSettingsFormProps> = ({ defaultValues, closeForm, mutationVariables }) => {
  const [t, i18n] = useCaseInsensitiveTranslation();

  const {
    control,
    handleSubmit,
    formState: { isSubmitting, errors },
    setError,
    clearErrors
  } = useForm<IUserSettingsFormValues>({
    defaultValues
  });
  const { data } = useQuery(LanguagesDocument);

  const updateCurrentUserSettings = (preferredLanguageFullCode: string) => i18n.changeLanguage(preferredLanguageFullCode);

  const onError = () => {
    setError('formError', {
      message: t('ui_common_error_save_item_failed')
    });
  };

  const onSubmit: SubmitHandler<IUserSettingsFormValues> = async formData => {
    const variables = {
      preferredLanguage: formData.preferredLanguage,
      hideDashboardWelcome: mutationVariables.hideDashboardWelcome,
      siteDetailsDefaultTab: formData.siteDetailsDefaultTab,
    };
    await mutate(UpdateCurrentUserSettingsDocument, variables, true, closeForm, onError);
    
    window.location.reload(); //HACK: Remove when sitedetails is in React
  };

  function cancelLanguageChange() {
    i18n.changeLanguage(defaultValues.preferredLanguage);
    closeForm();
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <CardContent>
        <FormElement label={t('ui_userprofile_labels_usersettings_preferredlanguage')}>
          <Controller
            name="preferredLanguage"
            control={control}
            defaultValue={defaultValues.preferredLanguage}
            render={({ field }) => (
              <Dropdown<Language>
                replaceItemElement={item => (
                  <div className="date-range-item-wrapper">
                    <Icon name="fa-globe" />
                    <span>{item.description}</span>
                  </div>
                )}
                selected={field.value}
                items={data?.languages}
                valuePath="fullCode"
                itemClicked={item => {
                  item?.value && updateCurrentUserSettings(item.value.toString());
                  field.onChange(item?.value);
                }}
              />
            )}
          />
        </FormElement>

        <FormElement label={t('ui_userprofile_labels_usersettings_sitedetailsdefaulttab')}>
          <Controller
            name="siteDetailsDefaultTab"
            control={control}
            defaultValue={defaultValues.siteDetailsDefaultTab}
            render={({ field }) => (
              <Dropdown<SiteDetailsTabEnum>
                replaceItemElement={item => (
                  <div className="date-range-item-wrapper">
                    <Icon name="fa-tab" />
                    <span>{item}</span>
                  </div>
                )}
                selected={field.value}
                items={ [SiteDetailsTabEnum.Details, SiteDetailsTabEnum.Graph, SiteDetailsTabEnum.Tanks] }
                itemClicked={item => {
                  field.onChange(item?.value);
                }}
              />
            )}
          />
        </FormElement>
        {errors.formError && <ErrorText className="text-align-center mar_tm">{errors.formError?.message}</ErrorText>}
      </CardContent>
      <CardFooter className="flex jsb">
        <Button variant="secondary" onClick={cancelLanguageChange}>
          {t('ui_common_cancel')}
        </Button>
        <Button onClick={() => clearErrors('formError')} type="submit" processing={isSubmitting}>
          {errors.formError ? t('ui_common_try_again') : t('ui_common_save')}
        </Button>
      </CardFooter>
    </form>
  );
};

export default UserSettingsForm;
