import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { UserSettingsDocument } from '../../../../../../custom_typings/graphql-codegen';
import CardHeader from '$components/cards/card-header/card-header.react';
import Card from '$components/cards/card/card.react';
import ClickableIcon from '$components/icons/icon-clickable/clickable-icon.react';

import UserSettingsForm from './module/user-settings-form/user-settings-form';
import UserSettingsView from './module/user-settings-view/user-settings-view';
import CardContent from '$components/cards/card-content/card-content.react';
import Skeleton from '$components/placeholders/skeleton/skeleton';
import ErrorText from '$components/texts/error-text/error-text.react';
import Button from '$components/buttons/button.react';
import { useQuery } from '$lib/hooks/fetch-utillities';

const UserSettingsCard = () => {
  const [t] = useTranslation();
  const { data, loading, revalidate } = useQuery(UserSettingsDocument);
  const [editingLanguage, setEditingLanguage] = useState(false);

  if (!data) {
    return (
      <Card>
        <CardHeader>{t('ui_userprofile_headers_usersettings')}</CardHeader>
        <CardContent className="relative">
          <Skeleton rowCount={2} rowHeight={20} />
          {!loading && (
            <div className="center-content absolute-fill flex column bg-white-80">
              <ErrorText bold>We could not fetch data</ErrorText>
              <Button variant="tertiary" onClick={revalidate}>
                Try again
              </Button>
            </div>
          )}
        </CardContent>
      </Card>
    );
  }
  
  return (
    <Card>
      <CardHeader
        action={
          editingLanguage ? (
            <ClickableIcon
              name="fa-times"
              onClick={() => setEditingLanguage(false)}
            />
          ) : (
            <ClickableIcon
              name="fa-pencil"
              onClick={() => setEditingLanguage(true)}
            />
          )
        }
      >
        {t('ui_userprofile_headers_usersettings')}
      </CardHeader>
      {data.currentUser.userSettings && (
        editingLanguage ? (
          <UserSettingsForm
            mutationVariables={{
              hideDashboardWelcome: data.currentUser.userSettings.hideDashboardWelcome,
              siteDetailsDefaultTab: data.currentUser.userSettings.siteDetailsDefaultTab,
            }}
            defaultValues={{
              preferredLanguage: data.currentUser.userSettings.preferredLanguage,
              siteDetailsDefaultTab: data.currentUser.userSettings.siteDetailsDefaultTab,
            }}
            closeForm={() => setEditingLanguage(false)}
          />
        ) : (
          <UserSettingsView
            currentUserPreferredLanguage={ data?.currentUser.userSettings.preferredLanguage || '' } 
            siteDetailsDefaultTab={ data?.currentUser.userSettings.siteDetailsDefaultTab }
          />
        )
      )}
    </Card>
  );
};

export default UserSettingsCard;
