import React, { FC, forwardRef, ChangeEventHandler, ChangeEvent, useEffect, useState } from 'react';
import './switch.css';
import classNames from "classnames";

interface ISwitchProps {
  checked: boolean;
  text?: string;
  className?: string;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  name?: string;
  disabled?: boolean;
}

const Switch: FC<ISwitchProps> = forwardRef<HTMLInputElement, ISwitchProps>(({ checked, text, className, onChange, disabled, name }, ref) => {
  const [checkedState, setChecked] = useState(checked);

  useEffect(() => {
    setChecked(checked);
  }, [checked]);

  function change(e: ChangeEvent<HTMLInputElement>) {
    onChange &&
      onChange({
        ...e,
        target: {
          ...e.target,
          value: e.target.checked as any,
          name: name as any
        }
      });
    setChecked(e.target.checked);
  }

  return (
    <div className={classNames(
      'component-switch-wrapper',
      className
    )}>
      <label className="component-switch">
        <input name={name} type="checkbox" ref={ref} checked={checkedState} onChange={change} disabled={disabled} />
        <span className="slider round"></span>
      </label>
      <span>{text}</span>
    </div>
  );
});

export default Switch;
