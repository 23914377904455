import React, { FC } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Button from '$components/buttons/button.react';
import CardContent from '$components/cards/card-content/card-content.react';
import CardFooter from '$components/cards/card-footer/card-footer.react';
import TextField from '$components/textbox/text-field.react';
import {
  CurrentUserInfo,
  ReactUpdateCurrentUserDocument
} from '$typings/graphql-codegen';
import ErrorText from '$components/texts/error-text/error-text.react';
import FormElement from '$components/forms/form-element/form-element.react';
import { IGeneralFormError } from '$lib/hooks/reactHookFormHelper';
import EmailField from '$components/inputs/email-input/email-field.react';
import { mutate } from '$lib/hooks/fetch-utillities';
import { isClientError } from '$pages/common/GraphQLFetcher';
import { ClientError } from 'graphql-request/dist/types';

export interface IUserInfoFormTypes extends IGeneralFormError {
  name: string;
  email: string;
  telephoneNumber: string | null;
  password: string
}

interface IUserInfoProps {
  defaultValues?: Partial<IUserInfoFormTypes>;
  mutationVariables: { user: CurrentUserInfo };
  closeForm: () => void;
}

const UserInfoForm: FC<IUserInfoProps> = ({
  defaultValues,
  closeForm,
  mutationVariables
}) => {
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors, isSubmitting },
    clearErrors
  } = useForm<IUserInfoFormTypes>({ defaultValues });
  const [t] = useTranslation();

  const onError = (e : ClientError) => {
    if (!e || !isClientError(401, e.response.errors)) {
      setError('formError', {
        message: t('ui_common_error_save_item_failed')
      });
    } else {      
      setError('formError', {
        message: t('ui_userprofile_labels_changepassword_current_password_wrong')
      });
    }
  };

  const onSubmit: SubmitHandler<IUserInfoFormTypes> = async formData => {
    const variables = {
      user: {
        avatarUrl: mutationVariables.user.avatarUrl,
        email: formData.email?.trim(),
        name: formData.name?.trim(),
        telephoneNumber: formData.telephoneNumber?.trim(),
        userId: mutationVariables.user.userId,
        password: formData.password
      }
    };

    await mutate(
      ReactUpdateCurrentUserDocument,
      variables,
      true,
      closeForm,
      onError
    );
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <CardContent>
        <FormElement label={t('ui_userprofile_labels_name')}>
          <TextField
            error={errors.name?.message}
            {...register('name', {
              required: {
                value: true,
                message: t('ui_common_validationerror_requiredfield')
              }
            })}
          />
        </FormElement>
        <FormElement label={t('ui_userprofile_labels_email')}>
          <EmailField register={register} error={errors.email?.message} required={true} />
        </FormElement>
        <FormElement label={t('ui_userprofile_labels_telephone')}>
          <TextField {...register('telephoneNumber')} />
        </FormElement>
        <FormElement label={t('ui_userprofile_labels_changepassword_current_password')}>
          <TextField
            type="password"
            required={true}
            error={errors.password?.message}
            {...register('password')}
          />
        </FormElement>
        {errors.formError && (
          <ErrorText className="text-align-center mar_tm">
            {errors.formError?.message}
          </ErrorText>
        )}
      </CardContent>
      <CardFooter className="flex jsb">
        <Button onClick={closeForm} variant="secondary">
          Cancel
        </Button>
        <Button
          type="submit"
          onClick={() => clearErrors('formError')}
          processing={isSubmitting}
        >
          {errors.formError ? t('ui_common_try_again') : t('ui_common_save')}
        </Button>
      </CardFooter>
    </form>
  );
};

export default UserInfoForm;
