import Button from '$components/buttons/button.react';
import CardContent from '$components/cards/card-content/card-content.react';
import CardFooter from '$components/cards/card-footer/card-footer.react';
import TextField from '$components/textbox/text-field.react';
import React, { FC } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import './edit-contact-info-form.css';
import {
  CurrentUserInfo,
  UpdateUserContactDocument
} from '$typings/graphql-codegen';
import FormElement from '$components/forms/form-element/form-element.react';
import ErrorText from '$components/texts/error-text/error-text.react';
import { IGeneralFormError } from '$lib/hooks/reactHookFormHelper';
import { mutate } from '$lib/hooks/fetch-utillities';
import EmailField from '$components/inputs/email-input/email-field.react';
import Checkbox from '$components/checkbox/checkbox.react';

export interface IEditContactInformationFormTypes extends IGeneralFormError {
  syncedWithUserInformation: boolean;
  name: string;
  email: string;
  telephone: string | null;
  description: string;
}

interface IEditContactInformationFormProps {
  closeForm: () => void;
  defaultValues?: Partial<IEditContactInformationFormTypes>;
  mutationVariables: {
    user: CurrentUserInfo;
    contactId?: string;
  };
}

const EditContactInfoForm: FC<IEditContactInformationFormProps> = ({
  closeForm,
  defaultValues,
  mutationVariables: { contactId, user }
}) => {
  const [t] = useTranslation();
  const {
    register,
    formState: { errors, isSubmitting },
    handleSubmit,
    watch,
    setError,
    clearErrors
  } = useForm<IEditContactInformationFormTypes>({ defaultValues });

  const onError = () => {
    setError('formError', {
      message: t('ui_common_error_save_item_failed')
    });
  };

  const onSubmit: SubmitHandler<IEditContactInformationFormTypes> = async formData => {
    const variables = {
      entity: {
        email: formData.email,
        buidId: user.buidId,
        contactId: contactId ? contactId : undefined,
        customerId: user.customerId,
        name: formData.name,
        telephoneNumber: formData.telephone,
        userId: user.userId ? user.userId.toString() : null,
        description: formData.description,
        syncedWithUserInformation: formData.syncedWithUserInformation
      }
    };

    await mutate(
      UpdateUserContactDocument,
      variables,
      true,
      closeForm,
      onError
    );
  };

  const disabled = watch('syncedWithUserInformation') || isSubmitting;
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <CardContent>
        <FormElement label={t("Synced with user information")}>
          <Checkbox
            className="edit-contact-form-checkbox"
            {...register('syncedWithUserInformation')}
            checked={defaultValues?.syncedWithUserInformation}
          />
        </FormElement>
        <FormElement label={t('ui_userprofile_labels_name')}>
          <TextField
            {...register('name', { required: true })}
            disabled={disabled}
            error={errors.name?.message}
          />
        </FormElement>
        <FormElement label={t('ui_userprofile_labels_email')}>
          <EmailField
            register={register}
            disabled={disabled}
            error={errors.email?.message}
          />
        </FormElement>
        <FormElement label={t('ui_userprofile_labels_telephone')}>
          <TextField
            {...register('telephone')}
            disabled={disabled}
            error={errors.telephone?.message}
          />
        </FormElement>
        <FormElement label={t('ui_common_description')}>
          <TextField
            {...register('description')}
            disabled={disabled}
            error={errors.description?.message}
          />
        </FormElement>
        {errors.formError && (
          <ErrorText className="text-align-center mar_tm">
            {errors.formError?.message}
          </ErrorText>
        )}
      </CardContent>
      <CardFooter className="flex jsb">
        <Button variant="secondary" onClick={closeForm}>
          {t('ui_common_cancel')}
        </Button>
        <Button
          type="submit"
          onClick={() => clearErrors('formError')}
          processing={isSubmitting}
        >
          {errors.formError ? t('ui_common_try_again') : t('ui_common_save')}
        </Button>
      </CardFooter>
    </form>
  );
};

export default EditContactInfoForm;
