import { default as React, FC } from 'react';
import './user-profile.css';

import UserInfoCard from './modules/user-info-card/user-info-card';
import ContactInfoCard from './modules/contact-info-card/contact-info-card';
import UserSettingsCard from './modules/user-settings-card/user-settings-card';
import AlarmSubscriptionCard from './modules/alarm-subscriptions-card/alarm-subscriptions-card';
import ChangePasswordCard from './modules/change-password-card/change-password-card';
import YaraTelemetryCard from '$pages/user-profile/react/modules/yara-telemetry-card/yara-telemetry-card';

const UserProfile: FC = () => {
  
  return (
    <div className="user-profile-content-wrapper">
      <div className="userprofile-item user">
        <UserInfoCard />
        <ContactInfoCard className="contactinformation-card" />
      </div>
      <div className="userprofile-item usersettings">
        <UserSettingsCard />
        <br/>
        <YaraTelemetryCard />
      </div>
      <div className="userprofile-item">
        <AlarmSubscriptionCard />
      </div>
      <div className="userprofile-item">
        <ChangePasswordCard />
      </div>
    </div>
  );
};

export default UserProfile;
