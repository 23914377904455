import { LanguagesDocument, SiteDetailsTabEnum } from '$typings/graphql-codegen';
import React, { FC } from 'react';
import CardContent from '$components/cards/card-content/card-content.react';
import { useQuery } from '$lib/hooks/fetch-utillities';
import SimpleTable from '$components/tables/simple-table/simple-table/simple-table.react';
import SimpleTableRow from '$components/tables/simple-table/simple-table-row/simple-table-row.react';
import SimpleTableCell from '$components/tables/simple-table/simple-table-cell/simple-table-cell.react';
import { useCaseInsensitiveTranslation } from '$lib/hooks/case-insensitive-translation';

interface IUserSettingsViewProps {
  currentUserPreferredLanguage: string;
  siteDetailsDefaultTab: SiteDetailsTabEnum
}

const UserSettingsView: FC<IUserSettingsViewProps> = ({ currentUserPreferredLanguage, siteDetailsDefaultTab }) => {
  const { data } = useQuery(LanguagesDocument);

  const [t] = useCaseInsensitiveTranslation();

  return (
    <CardContent>
      <SimpleTable>
        <SimpleTableRow>
          <SimpleTableCell>{t('ui_userprofile_labels_usersettings_preferredlanguage')}</SimpleTableCell>
          <SimpleTableCell>{data?.languages.find(l => l.fullCode === currentUserPreferredLanguage)?.description}</SimpleTableCell>
        </SimpleTableRow>
        <SimpleTableRow>
          <SimpleTableCell>{t('ui_userprofile_labels_usersettings_sitedetailsdefaulttab')}</SimpleTableCell>
          <SimpleTableCell>{siteDetailsDefaultTab}</SimpleTableCell>
        </SimpleTableRow>
      </SimpleTable>
    </CardContent>
  );
};

export default UserSettingsView;
